import React from "react";

const AccroPress = require("@accrosoft-ltd/accropress-node-sdk");

/**
 * Local Storage Hook
 * @param {*} localStorageKey
 */
export const useStateWithLocalStorage = (localStorageKey) => {
  const [value, setValue] = React.useState(
    (typeof window !== "undefined" && localStorage.getItem(localStorageKey)) ||
      ""
  );

  React.useEffect(() => {
    if (typeof window !== "undefined") {
      localStorage.setItem(localStorageKey, value);
    }
  }, [value]);

  return [value, setValue];
};

/**
 * Session Storage Hook
 * @param {*} sessionStorageKey
 */
export const useStateWithSessionStorage = (sessionStorageKey) => {
  const [value, setValue] = React.useState(
    (typeof window !== "undefined" &&
      sessionStorage.getItem(sessionStorageKey)) ||
      ""
  );

  React.useEffect(() => {
    if (typeof window !== "undefined") {
      sessionStorage.setItem(sessionStorageKey, value);
    }
  }, [value]);

  return [value, setValue];
};

/**
 * Create Signals Session
 * @param {*} eventObject
 * @param {*} collectionName
 */
export const createSignalsSession = async (
  userId,
  sessionId,
  siteConfig,
  location,
  fingerprint
) => {
  if (siteConfig && siteConfig.useSignalsAnalytics === true) {
    if (userId && sessionId && siteConfig && siteConfig.signalsSiteID) {
      const AccroPressSignalsAPI = new AccroPress.SignalsAPIProvider({
        siteId: siteConfig.signalsSiteID,
        userId,
        sessionId,
      });

      const AccroPressSignalsAPIAnalytics = AccroPressSignalsAPI.analytics;
      const AccroPressSignalsAPISites = AccroPress.sites;

      const sessionData = await AccroPressSignalsAPIAnalytics.getSingleSessions(
        sessionId
      );

      if (sessionData && sessionData.sessionId) {
        return sessionData;
      } else {
        fingerprint = fingerprint ? JSON.parse(fingerprint) : null;

        const fingprintComponentsData =
          fingerprint && fingerprint.components ? fingerprint.components : {};

        const newSessionData = await AccroPressSignalsAPIAnalytics.insertSession(
          {
            userId: userId,
            sessionId: sessionId,
            userIp: "1.1.1.1",
            visitDate: new Date().toISOString(),
            device: {
              availableScreenResolution:
                fingprintComponentsData.availableScreenResolution,
              audio: fingprintComponentsData.audio,
              colorDepth: fingprintComponentsData.colorDepth,
              cpuClass: fingprintComponentsData.cpuClass,
              deviceMemory: fingprintComponentsData.deviceMemory,
              hardwareConcurrency: fingprintComponentsData.hardwareConcurrency,
              osCpu: fingprintComponentsData.osCpu,
              platform: fingprintComponentsData.platform,
              languages: fingprintComponentsData.languages,
              timezone: fingprintComponentsData.timezone,
              timezoneOffset: fingprintComponentsData.timezoneOffset,
              touchSupport: fingprintComponentsData.touchSupport,
            },
            browser: {
              chrome: fingprintComponentsData.chrome,
              cookiesEnabled: fingprintComponentsData.cookiesEnabled,
              fonts: fingprintComponentsData.fonts,
              indexedDB: fingprintComponentsData.indexedDB,
              localStorage: fingprintComponentsData.localStorage,
              openDatabase: fingprintComponentsData.openDatabase,
              plugins: fingprintComponentsData.plugins,
              pluginSupport: fingprintComponentsData.pluginSupport,
              screenResolution: fingprintComponentsData.screenResolution,
              sessionStorage: fingprintComponentsData.sessionStorage,
              vendor: fingprintComponentsData.vendor,
            },
            location: {},
            referer: "",
            pages: location,
            eventData: [
              {
                eventLabel: "New Session",
                eventAction: "initalised",
                eventCategory: "session",
              },
            ],
            sessionScrap: {
              emptyEvalLength: fingprintComponentsData.emptyEvalLength,
              errorFF: fingprintComponentsData.errorFF,
              productSub: fingprintComponentsData.productSub,
            },
          }
        );

        return newSessionData;
      }
    } else {
      console.error(
        "Params required to create a signals session were not provided"
      );
    }
  }
};

/**
 * trackAnalyticsEvent
 * @param {*} eventObject
 */
export const trackAnalyticsEvent = (
  eventObject,
  userId,
  sessionId,
  siteConfig
) => {
  // Push events to datalayer
  window.dataLayer = window && window.dataLayer ? window.dataLayer : [];

  window.dataLayer.push(eventObject);

  // Push events to Signals
  _trackSignalsEvent(eventObject, userId, sessionId, siteConfig);
};

/**
 * _trackSignalsEvent
 * sends Signals tracking event
 * @param eventObject
 * @private
 */
const _trackSignalsEvent = async (
  eventObject,
  userId,
  sessionId,
  siteConfig
) => {
  if (siteConfig && siteConfig.useSignalsAnalytics === true) {
    if (userId && sessionId && siteConfig && siteConfig.signalsSiteID) {
      const AccroPressSignalsAPI = new AccroPress.SignalsAPIProvider({
        siteId: siteConfig.signalsSiteID,
        userId,
        sessionId,
      });
      const AccroPressSignalsAPIAnalytics = AccroPressSignalsAPI.analytics;
      const newEventData = await AccroPressSignalsAPIAnalytics.addSessionEvent(
        sessionId,
        eventObject
      );

      return newEventData;
    } else {
      console.error("Params required to track an event were not provided");
    }
  }
};

/**
 * trackAnalyticsPageView
 * @param {*} eventObject
 */
export const trackAnalyticsPageView = (
  location,
  userId,
  sessionId,
  siteConfig
) => {
  if (siteConfig && siteConfig.useSignalsAnalytics === true) {
    if (
      location &&
      userId &&
      sessionId &&
      siteConfig &&
      siteConfig.signalsSiteID
    ) {
      // Push events to Signals
      _trackSignalsPageView(location, userId, sessionId, siteConfig);
    } else {
      console.error("Params required to track an event were not provided");
    }
  }
};

/**
 * _trackSignalsPageView
 * sends Signals tracking event
 * @param eventObject
 * @private
 */
const _trackSignalsPageView = async (
  eventObject,
  userId,
  sessionId,
  siteConfig
) => {
  if (siteConfig && siteConfig.useSignalsAnalytics === true) {
    if (
      eventObject &&
      userId &&
      sessionId &&
      siteConfig &&
      siteConfig.signalsSiteID
    ) {
      const AccroPressSignalsAPI = new AccroPress.SignalsAPIProvider({
        siteId: siteConfig.signalsSiteID,
        userId,
        sessionId,
      });
      const AccroPressSignalsAPIAnalytics = AccroPressSignalsAPI.analytics;

      eventObject.timeOn = 1;
      eventObject.url = eventObject.href;
      eventObject.name = eventObject.pathname;

      const newEventData = await AccroPressSignalsAPIAnalytics.addSessionPages(
        sessionId,
        eventObject
      );

      return newEventData;
    } else {
      console.error("Params required to track an event were not provided");
    }
  }
};
