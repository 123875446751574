import { Link } from "gatsby";
import React from "react";

const onError = (e) => {
  e.target.onerror = null
  e.target.src = "/logos/HireRoad_reverse.svg"
}

const Footer = (props) => (
  <footer className="footer-container">
    {props.smartfeedCompanyData &&
      props.smartfeedCompanyData.CompanyName &&
      props.smartfeedCompanyData.CompanyName !== "DefaultCompany" && (
        <div className="footer-register-wrap">
          {props.apiKey && !props.jobAlertsPage && props.displayJACTA && (
            <div className="footer-register-container container">
              <div className="vf-careers-site-spacing-cta">
                <article aria-label="Subscribe to Job Alerts">
                  <div className="ctacard soft">
                    <div className="ctacard__body d-flex justify-content-center">
                      <div className="col-md-10 col-lg-6 text-center">
                        <h4 className="ctacard__body__title">
                          <span>Can't find a job role to suit you?</span>
                        </h4>
                        <p className="ctacard__body__summary">
                          Get job alerts straight to your inbox and be the first
                          to see our new vacancies.
                        </p>
                        <Link to={`/job-alerts/`} className="btn--action">
                          Subscribe to Job Alerts
                        </Link>
                      </div>
                    </div>
                  </div>
                </article>
              </div>
            </div>
          )}
        </div>
      )}
    <div className="footer-attribution-wrap">
      <div className="footer-attribution-container container-fluid">
        <div className="row">
          <div className="col-sm-3">
            <img
                src={`/logos/HireRoad_reverse.svg`}
                onError={onError}
                className="footer-logo"
                alt="HireRoad"
                width="auto"
                height="auto"
              />
          </div>
          <div className="col-sm-3 d-none d-md-block">
            <strong>United States</strong>
            <p>
              4350 Fairfax Dr #830
              <br />
              Arlington, VA 22203, USA
              <br />
              p. 703 350 4496
              <br />
              e.{" "}
              <a href="mailto:usersupport@hireroad.com">
                usersupport@hireroad.com
              </a>
            </p>
          </div>
          <div className="col-sm-3 d-none d-md-block">
            <strong>Australia</strong>
            <p>
              Level 3, 600 Victoria St
              <br />
              Richmond, VIC, 3121
              <br />
              p. 1800 642 638
            </p>
          </div>
          <div className="col-sm-3 d-none d-md-block">
            <strong>United Kingdom</strong>
            <p>
              21 Jubilee Drive
              <br />
              Loughborough
              <br />
              Leicestershire
              <br />
              LE11 5XS
              <br />
              Tel.: 01509 236 434
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-3 d-none d-md-block">
            <h4>Support</h4>
          </div>
          <div className="col-sm-3 d-none d-md-block">
            <strong>HR Recruit & HR Onboard</strong>
            <br />
            <span>UK: +44 01509 236 434</span>
            <br />
            <span></span>
            <a href="mailto:support@vacancyfiller.com">
              support@vacancyfiller.com
            </a>
            <p></p>
            <strong>
              <a
                href="https://app.vacancy-filler.co.uk/VFMVC/Account/Login"
                target="noopener"
                rel="noopener"
                title="Vacancy Filler Login"
              >
                Vacancy Filler Login
              </a>
            </strong>
          </div>
          <div className="col-sm-3 d-none d-md-block">
            <strong>HR Recruit & HR Onboard</strong>
            <br />
            <strong>
              <a
                href="https://acendrelearn.zendesk.com/hc/en-us"
                target="noopener"
              >
                Inquisiq Support Site
              </a>
            </strong>
          </div>
          <div className="col-sm-3 d-none d-md-block">
            <strong>Acendre</strong>
            <br />
            <span>
              <strong>AU: </strong>1 800 642 638
            </span>
            <br />
            <span></span>
            <a href="mailto:support@acendre.com">support@acendre.com</a>
            <br />
            <strong> US: </strong>
            <a href="mailto:Usersupport@acendre.com">usersupport@acendre.com</a>
            <p></p>
            <strong>
              <a
                href="https://developer.acendre.com/"
                target="noopener"
                rel="noopener"
                title="Acendre Developer Login"
              >
                Acendre Developer Portal
              </a>
            </strong>
          </div>
        </div>
        <div className="row">
          <div className="footer-socials p-3 col-sm-6">
            <a
              href="https://twitter.com/HireRoadHR"
              target="noopener"
              rel="nofollow"
            >
              <span className="p-3 fab fa-twitter fa-2x"></span>
            </a>
            <a
              href="https://www.linkedin.com/company/wearehireroad/"
              target="noopener"
              rel="nofollow"
            >
              <span className="p-3 fab fa-linkedin fa-2x"></span>
            </a>
            <a
              href="https://www.facebook.com/wearehireroad"
              target="noopener"
              rel="nofollow"
            >
              <span className="p-3 fab fa-facebook fa-2x"></span>
            </a>
          </div>
          <div className="p-3 col-sm-6">
            <a
              className="footer-button nav-action-sm"
              role="button"
              href="https://hireroad.com/request-a-demo"
            >
              Request a Demo
            </a>
          </div>
        </div>
        <div className="row">
          <div className="p-3 col-sm-6">
            <p>© 2022 HireRoad. All rights reserved</p>
          </div>
          <div className="footer-socials p-3 col-sm-6">
            <a href="https://hireroad.com/terms-of-use" target="noopener">
              Terms of Use
            </a>{" "}
            /{" "}
            <a href="https://hireroad.com/privacy-policy" target="noopener">
              Privacy Policy
            </a>{" "}
            /{" "}
            <a href="https://hireroad.com/sitemap" target="noopener">
              Sitemap
            </a>
          </div>
        </div>
        <div className="row">
        <span className="accessibility-selectors">
            <ul>
              <li className="fontSize_Selection">
                <button
                  className="fontSize_Selection_trigger fontSize_Selection_trigger_normal"
                  alt="Normal Font Size"
                  title="Normal Font Size"
                  onClick={() => props.setFontSize('normal')}
                >
                  <span className="fal fa-font"></span>
                </button>
                <button
                  className="fontSize_Selection_trigger fontSize_Selection_trigger_medium"
                  alt="Medium Font Size"
                  title="Medium Font Size"
                  onClick={() => props.setFontSize('medium')}
                >
                  <span className="fal fa-font"></span>
                </button>
                <button
                  className="fontSize_Selection_trigger fontSize_Selection_trigger_large"
                  alt="Large Font Size"
                  title="Large Font Size"
                  onClick={() => props.setFontSize('large')}
                >
                  <span className="fal fa-font"></span>
                </button>
              </li>
              <li className="contrast_Selection">
                {props.colourContrast ? (
                  <button
                    className="contrast_trigger contrast_trigger_light"
                    alt="Toggle Dark Mode"
                    title="Toggle Dark Mode"
                    onClick={() => props.setColourContrast(false)}
                  >
                    <span className="fas fa-adjust"></span>
                  </button>
                ) : (
                  <button
                    className="contrast_trigger contrast_trigger_dark"
                    onClick={() => props.setColourContrast(true)}
                  >
                    <span className="fad fa-adjust"></span>
                  </button>
                )}
              </li>
            </ul>
          </span>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;

{
  /* <div className="left text-center text-lg-left">
          <div className="logo col-md-3 col-sm-12">
            <Link to="/">
              <img width="auto" height="auto" src={Logo} alt="HireRoad" />
            </Link>
            
          </div>
          <div className="logo col-md-3 col-sm-12">
          <a href="https://hireroad.com/terms-of-use" target="_blank">
            Support
          </a>
          </div>
        </div>

        <div className="center text-center text-lg-center">
          <p>© 2022 HireRoad. All rights reserved</p>
        </div>

        <div className="right text-center text-lg-right">
          <a href="https://hireroad.com/terms-of-use" target="_blank">
            Terms of Use
          </a>{" "}
          /{" "}
          <a href="https://hireroad.com/privacy-policy" target="_blank">
            Privacy Policy
          </a>{" "}
          /{" "}
          <a href="https://hireroad.com/sitemap" target="_blank">
            Sitemap
          </a>
        </div> */
}
